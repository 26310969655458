.rbc-toolbar {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.rbc-toolbar-label::first-letter {
    text-transform: uppercase !important;
}

.rbc-toolbar-label {
    font-family: 'DM-Bold' !important;
}

.rbc-overlay-header::first-letter {
    text-transform: uppercase !important;
}

.rbc-overlay-header {
    font-family: 'DM-Medium' !important;
}

.rbc-event-content {
    font-family: 'DM-Regular' !important;
}

.rbc-overlay {
    border-radius: 10px;
}

.rbc-btn-group > button {
    font-family: 'DM-Medium' !important;
}

.rbc-button-link {
    font-family: 'DM-Medium' !important;
}